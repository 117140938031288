import { Modal } from 'antd';
import AddEditEssayQuestion from '../AddEditEssayQuestion';
import AddEditChooseQuestion from '../AddEditChooseQuestion';
import React, { useState } from 'react';
import styles from './QuestionBankCategory_modal.module.css';

const QuestionBankCategory_modal = ({essay , type , ques , reFetch , setReFetch , homeworkID , quizID }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  return (
    <>
      <button
          className="editQuestionBtn"
          onClick={showModal}
        >
          تعديل
        </button>
      <Modal 
        open={isModalOpen} 
        footer={null} 
        onCancel={() => setIsModalOpen(false)} 
        wrapClassName='w-full'
        className={styles.modalCustomClass}
        width={1200}
        centered
        destroyOnClose
      >
        {essay ?
        <AddEditEssayQuestion id={homeworkID || quizID}  edit={true} type={type} ModalID={ques.key} setRefetch={setReFetch} refetch={reFetch} setIsModalOpen={setIsModalOpen}/> : <AddEditChooseQuestion id={homeworkID || quizID} edit={true}  type={type}   ModalID={ques.key} setRefetch={setReFetch} refetch={reFetch} setIsModalOpen={setIsModalOpen}/>
        }
      </Modal>
    </>
  );
};
export default QuestionBankCategory_modal;