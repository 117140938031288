import Footer from "../Pages/LandingPage/Components/Footer";
import Navbar from "./Navbar";
import { Outlet, useLocation } from "react-router-dom";

function Layout() {
  const location = useLocation();
  const hideFooter = location.pathname.startsWith('/home/exams/solve-exam/') || location.pathname.startsWith('/question-bank/')  ;

  return (
    <>
      <div id="top"></div>
      <Navbar />
      <div className="min-h-screen h-full pt-28">
        <Outlet />
      </div>
      {!hideFooter && <Footer />}
    </>
  );
}

export default Layout;
