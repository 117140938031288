import React from "react";

export const DisplayQuestion = ({ state, children }) => {
  return (
    <div
      className={`left-question-container questions-section flex min-h-full w-3/4 justify-center flex-col  items-center px-[5%] py-[40px] md:w-full mx-auto ${
        state.response && "hidden"
      }`}
    >
      {children}
    </div>
  );
};
