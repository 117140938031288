import { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useAxios from "../../../../MainComponents/Hooks/useAxios.jsx";
import { useAuthState } from "../../../../MainComponents/GlobalContext.jsx";
import HelmetTags from "../../../../MainComponents/HelmetTags.jsx";
import Loader from "../../../../MainComponents/Loader.jsx";
import QuestionBankCategory_modal from "./modals/QuestionBankCategory_modal.jsx";

function QuestionBankCategory({
  type,
  QuizQuestionsData,
  refetchQuiz,
  setRefetchQuiz,
  id,
  lectureQuestion,
  homeworkID,
  quizID
}) {
  const Modal = useRef(null);
  const AuthState = useAuthState();
  const navigate = useNavigate();
  const { categoryID } = useParams();

  const [deleteId, setDeleteId] = useState("");
  const [reFetch, setReFetch] = useState(false);
  const [submitDelete, setSubmitDelete] = useState({
    flag: "",
    dependency: false,
    data: {},
  });

  const [allQuestionsData, allQuestionsErrors, loading] = useAxios(
    `${process.env.REACT_APP_CATEGORY_QUESTIONS_API}${categoryID}`,
    "GET",
    !type,
    reFetch
  );

  const [deleteQuestionSuccess] = useAxios(
    process.env.REACT_APP_DELETE_QUESTION_API,
    "POST",
    submitDelete.flag,
    submitDelete.dependency,
    submitDelete.data,
    true
  );

  useEffect(() => {
    if (deleteQuestionSuccess) {
      type ? setRefetchQuiz(!refetchQuiz) : setReFetch(!reFetch);
      setDeleteId("");
    }
  }, [deleteQuestionSuccess]);

  const handleDelete = () => {
    setSubmitDelete({
      flag: "deleteQuestion",
      dependency: !submitDelete.dependency,
      data: { id: deleteId },
    });
  };

  const renderOptions = (ques) => (
    <div className="questionOptions">
      {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
        AuthState.userData?.admin?.permissions?.some((permission) =>
          permission.name.startsWith("questionbank_deletequestion")
        ) ||
        AuthState.userData?.admin?.permissions?.some((permission) =>
          permission.name.startsWith("lecture_addquiz")
        ) ||
        AuthState.userData?.admin?.permissions?.some((permission) =>
          permission.name.startsWith("lecture_addHw")
        )) && (
        <button
          className="deleteQuestionBtn"
          onClick={() => {
            Modal.current.classList.remove("hide");
            setDeleteId(ques.key);
          }}
        >
          حذف
        </button>
      )}
      {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
        AuthState.userData?.admin?.permissions?.some((permission) =>
          permission.name.startsWith("questionbank_editquestion")
        ) ||
        AuthState.userData?.admin?.permissions?.some((permission) =>
          permission.name.startsWith("lecture_addquiz")
        ) ||
        AuthState.userData?.admin?.permissions?.some((permission) =>
          permission.name.startsWith("lecture_addHw")
        )) && (
      <QuestionBankCategory_modal homeworkID={homeworkID} quizID={quizID} lectureQuestion={lectureQuestion}  setReFetch={setRefetchQuiz || setReFetch} reFetch={reFetch || refetchQuiz} essay={ques.is_essay} type={type} categoryID={categoryID} ques={ques}/>
      )}
    </div>
  );

  const renderAnswers = (ques) => {
    if (ques.is_essay) return null;
    const EnglishLetters = ["A -", "B -", "C -", "D -"];
    const answers = ["answer1", "answer2", "answer3", "answer4"].map(
      (answerKey, index) => (
        <div
          key={answerKey}
          className={`categorySingleAnswer answer${index + 1}Wrapper ${
            ques.correct_answer === answerKey ? "correctSingleAnswer" : ""
          }`}
        >
          <p>{EnglishLetters[index]}</p>
          <div dangerouslySetInnerHTML={{ __html: ques[answerKey] }} />
        </div>
      )
    );

    return (
      <div>
         <div className="categoryAnswersWrapper">
        {answers}
       
      </div>
      {ques.feedback !== "" && <div className="feedbackWrapper">
          <p className="text-black border-b border-b-yellow-400">الفيدباك</p>
          <div
            className="text-black"
            dangerouslySetInnerHTML={{ __html: ques.feedback }}
          />
        </div>}
         
      </div>
     
    );
  };

  const renderQuestions = (questions) => {
    return questions?.map((ques, idx) => (
      <div key={idx} className="singleQuestionWrapper">
        <div className="optionsQuestionWrapper">
          {renderOptions(ques)}
          <div className="categoryQuestionWrapper">
            <div className="essayTitleWrapper">
              {!type && <h4>{ques.difficulty}</h4>}
              {ques.is_essay === 1 && <span>سؤال مقالي</span>}
              <p>{`السؤال رقم ${ques.n}`}</p>
            </div>
            <div
              className="text-black"
              dangerouslySetInnerHTML={{ __html: ques.question }}
            />
          </div>
        </div>
        {renderAnswers(ques)}
      </div>
    ));
  };

  return (
    <section
      className={`h-full w-full overflow-y-auto ${
        !type && "px-20"
      } py-20 dark:bg-dark dark:text-light sm:px-10`}
    >
      {!type && <HelmetTags title="بنك الاسئلة | Sir karam makram" />}
      <div
        ref={Modal}
        className="modal hide absolute bottom-1/2 right-1/2 w-80 translate-x-1/2 translate-y-1/2 flex-col items-center gap-4 rounded-2xl bg-red-200 p-4"
      >
        <p className="mb-4 w-full text-center">تأكيد اجراء الحذف؟</p>
        <div className="flex items-center justify-center gap-5">
          <button
            onClick={() => {
              Modal.current.classList.add("hide");
              setDeleteId("");
            }}
            className="closeModal"
          >
            الغاء
          </button>
          <button onClick={handleDelete} className="confirmModal">
            تأكيد
          </button>
        </div>
      </div>
      {!type && allQuestionsData?.name && (
        <h2 className="mb-16 w-full text-end text-4xl font-bold sm:text-center">
          اسئلة تصنيف {allQuestionsData.name}
        </h2>
      )}
      <div className="all-questions flex h-auto w-full flex-col gap-14 px-10">
        {
        
          renderQuestions(
            QuizQuestionsData?.length > 0
              ? QuizQuestionsData
              : allQuestionsData?.data
          )
        }
        {allQuestionsErrors && (
          <p className="w-full text-center text-red-500">
            {allQuestionsErrors}
          </p>
        )}
      </div>
    </section>
  );
}

export default QuestionBankCategory;
