import { useEffect, useState } from "react";
import SingleCourse from "../../../../MainComponents/SingleCourse";
import SingleChapter from "../../../../MainComponents/SingleChapter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useAuthState } from "../../../../MainComponents/GlobalContext.jsx";
import useAxios from "../../../../MainComponents/Hooks/useAxios.jsx";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import { Loader } from "../../../../MainComponents";

function AdminLectures() {
  const [filterStage, setFilterStage] = useState(() => {
    // Retrieve filterStage from localStorage, or default to null
    const savedFilterStage = localStorage.getItem("filterStage");
    return savedFilterStage ? JSON.parse(savedFilterStage) : null;
  });

  const navigate = useNavigate();
  const AuthState = useAuthState();
  const [reFetch, setReFetch] = useState(false);
  const [allLecturesData, allLecturesErrors, allLecturesloading] = useAxios(
    process.env.REACT_APP_ADMIN_LECTURES_TAB_API,
    "GET",
    "GET",
    reFetch
  );

  useEffect(() => {
    // Save filterStage to localStorage whenever it changes
    localStorage.setItem("filterStage", JSON.stringify(filterStage));
  }, [filterStage]);

  const filteredLectures = allLecturesData?.data?.filter((lecture) => {
    if (filterStage === null) return true;
    return lecture.stage === filterStage;
  });

  console.log(filteredLectures, allLecturesData);

  return (
    <section
      className={`  h-auto  w-full  px-20 py-20  dark:bg-dark dark:text-light `}
    >
      <HelmetTags title="المحاضرات | Sir karam makram"></HelmetTags>
      <div className="mb-32 flex flex-col items-end gap-20">
        <h2 className="w-full text-end text-4xl font-bold md:text-center">
          المحاضرات
        </h2>
        {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
          AuthState.userData?.admin?.permissions?.some((permission) =>
            permission.name.startsWith("lecture_add")
          )) && (
          <div className="flex w-full justify-end gap-10 md:justify-evenly">
            <button
              onClick={() => navigate("/admin/lectures/add-lecture")}
              className="ml-4 rounded-xl border-2 border-secondary bg-light px-2 py-1 font-semibold text-secondary transition-all duration-300 hover:bg-secondary  active:scale-90 "
            >
              <FontAwesomeIcon className="mr-4" icon={faPlus} />
              اضافة محاضرة
            </button>
          </div>
        )}
        <div className="flex w-full flex-row-reverse gap-10 md:justify-evenly">
          <button
            onClick={() => setFilterStage(1)}
            className={`${
              filterStage === 1
                ? "bg-secondary text-light"
                : "border-secondary bg-light"
            } ml-4 rounded-xl border-2 px-2 py-1 font-semibold  transition-all duration-300 hover:bg-secondary hover:text-light  active:scale-90 `}
          >
            الصف الاول
          </button>
          <button
            onClick={() => setFilterStage(2)}
            className={`${
              filterStage === 2
                ? "bg-secondary text-light"
                : "border-secondary bg-light"
            } ml-4 rounded-xl border-2 px-2 py-1 font-semibold transition-all duration-300 hover:bg-secondary hover:text-light  active:scale-90 `}
          >
            الصف الثاني
          </button>
          <button
            onClick={() => setFilterStage(3)}
            className={`${
              filterStage === 3
                ? "bg-secondary text-light"
                : "border-secondary bg-light"
            } ml-4 rounded-xl border-2 px-2 py-1 font-semibold transition-all duration-300 hover:bg-secondary hover:text-light  active:scale-90 `}
          >
            الصف الثالث
          </button>
        </div>
      </div>

      {allLecturesloading ? (
        <Loader />
      ) : (
        <div className="lectures grid-auto-fit">
          {filteredLectures?.length === 0 ? (
            <p>لا يوجد محاضرات</p>
          ) : (
            filteredLectures?.map((Card) => {
              if (Card.is_chapter) {
                return (
                  <SingleChapter
                    reFetch={reFetch}
                    setReFetch={setReFetch}
                    admin
                    chapter={Card}
                    key={Card.key}
                  />
                );
              } else {
                return (
                  <SingleCourse
                    reFetch={reFetch}
                    setReFetch={setReFetch}
                    admin
                    lecture={Card}
                    key={Card.key}
                  />
                );
              }
            })
          )}
        </div>
      )}
    </section>
  );
}

export default AdminLectures;
