//!you may need to change record.id to record.key

import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import useTable from "../../../../MainComponents/Hooks/useTable";
import toast from "react-hot-toast";
import { useAuthState } from "../../../../MainComponents/GlobalContext";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import { CSVLink } from "react-csv";

function Codes({ activation }) {
  const [submitGenerate, setSubmitGenerate] = useState({
    flag: "",
    dependency: false,
    data: {},
  });

  const [generateCodesSuccess, generateCodesErrors, submitLoading] = useAxios(
    process.env.REACT_APP_GENERATE_CODES_API,
    "POST",
    submitGenerate.flag,
    submitGenerate.dependency,
    submitGenerate.data,
    true
  );
  const table = useTable("codes", "", "", generateCodesSuccess?.data);
  const [border, setBorder] = useState(false);
  const AuthState = useAuthState();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
    setValue
  } = useForm({ mode: "onTouched" , 
    defaultValues:{
      count:1,
      length:6,
      number_lectures:1,
      type:"mixed",
      stage:3
    }
  });

  const onSubmit = (data) => {
    setSubmitGenerate({
      flag: "generateCodesRequest",
      dependency: !submitGenerate.dependency,
      data: data,
    });
  };
  useEffect(() => {
    //! reset the form on success submit

    if (generateCodesSuccess) {
      setBorder(true);
      reset();
    }
  }, [generateCodesSuccess]);

  async function copy(code) {
    try {
      await navigator.clipboard.writeText(code);
      toast.success("تم النسخ إلى الحافظة");
    } catch (err) {
      console.error("Failed to copy: ", err);
      toast.error("النسخ إلى الحافظة فشل من فضلك حاول مرة اخري");
    }
  }

  return (
    <section className="h-full w-full overflow-y-auto px-20 py-20 dark:bg-dark dark:text-light sm:px-10">
      <HelmetTags title="الاكواد | Sir karam makram"></HelmetTags>{" "}
      <h2 className="w-full text-end text-4xl font-bold md:text-center">
        الأكواد
      </h2>
      {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
        AuthState.userData?.admin?.permissions?.some((permission) =>
          permission.name.startsWith("code_add")
        )) && (
        <div className="form-codes-container my-20">
          <h2 className="my-20 text-center text-3xl font-semibold">
            من فضلك قم بملئ البيانات للإستخراج
            {activation ? "activation codes" : " الأكواد"}
          </h2>
          <div className=" form-codes flex w-full  items-start justify-center gap-20 lg:flex-col lg:items-center lg:justify-start ">
            <div
              className={`codes flex h-auto max-h-[380px] max-h-[380px] w-1/2 flex-wrap items-center justify-center gap-7 overflow-y-auto p-4 md:w-full ${
                border ? "rounded-xl border-2 border-secondary" : ""
              } `}
            >
              {generateCodesSuccess?.data && (
                <div className="flex flex-col items-center gap-4">
                  <button className="approve-btn rounded-[100px] border-2 border-secondary px-10 py-5 text-center text-2xl text-secondary transition-all duration-300 hover:scale-105 hover:bg-logoYellow hover:border-logoYellow active:scale-90">
                    <CSVLink
                      data={generateCodesSuccess?.data}
                      filename={`Generated Code Excel.csv`}
                    >
                      Export
                    </CSVLink>
                  </button>
                  <div
                    className={`codes flex h-auto max-h-[380px] w-[320px] flex-wrap items-center justify-center gap-7 overflow-y-auto p-4 md:w-[250px] ${
                      border ? "rounded-xl border-2 border-secondary" : ""
                    } `}
                  >
                    {generateCodesSuccess?.data?.map((code) => (
                      <button
                        className="code-btn !bg-secondary"
                        onClick={() => copy(code.code)}
                      >
                        {code.code}
                      </button>
                    ))}
                  </div>
                </div>
              )}
            </div>
            <div className="cardS1">
              <form
                method="post"
                onSubmit={handleSubmit(onSubmit)}
                className="!w-full form"
                // className="flex h-fit w-full flex-col  items-center justify-start gap-6 rounded-3xl bg-red-200 p-10 shadow-lg shadow-red-200/30 md:w-full "
              >
                <div className="first-row flex w-full items-center justify-between gap-6 md:flex-col md:justify-start">
                  {/** number of codes field */}

                  <div className=" flex w-1/2 flex-col items-end justify-center gap-2 md:w-full mb-4">
                    <label className="w-full truncate" htmlFor="count">
                      عدد الأكواد{" "}
                    </label>
                    <input
                      className="input"
                      type="number"
                      min={1}
                      id="count"
                      placeholder="000"
                      
                      name="count"
                      autoComplete="on"
                      {...register("count", {
                        required: true,
                        valueAsNumber: true,
                        
                      })}
                    />

                    {errors.count && (
                      <p className="text-[12px] text-red-900 ">
                        {errors.count.type === "required" &&
                          "برجاء ملئ هذا الحقل"}{" "}
                      </p>
                    )}
                    {
                      //!-------server errors -----

                      generateCodesErrors &&
                        generateCodesErrors?.response?.data?.errors?.count && (
                          <p className="w-full text-end text-[12px] text-red-900  ">
                            {
                              generateCodesErrors?.response?.data?.errors
                                ?.count[0]
                            }
                          </p>
                        )
                    }
                  </div>

                  {/** length of code field */}

                  <div className=" flex w-1/2 flex-col items-end justify-center gap-2 md:w-full mb-4">
                    <label className="w-full truncate" htmlFor="length">
                      عدد أحرف الكود{" "}
                    </label>
                    <input
                      className="input"
                      type="number"
                      min={1}
                      max={255}
                      id="length"
                      placeholder="000"
                      name="length"
                      autoComplete="on"
                      {...register("length", {
                        required: true,
                        valueAsNumber: true,
                        max: 255,
                        min: 1,
                      })}
                    />

                    {errors.length && (
                      <p className="text-[12px] text-red-900 ">
                        {errors.length.type === "required" &&
                          "برجاء ملئ هذا الحقل"}{" "}
                        {errors.length.type === "max" &&
                          "أقصي عدد للحروف هو ٢٢٥ حرف"}
                        {errors.length.type === "min" &&
                          " من فضلك قم بكتابة حرف واحد علي الاقل"}
                      </p>
                    )}
                    {
                      //!-------server errors -----

                      generateCodesErrors &&
                        generateCodesErrors?.response?.data?.errors?.length && (
                          <p className="w-full text-end text-[12px] text-red-900  ">
                            {
                              generateCodesErrors?.response?.data?.errors
                                ?.length[0]
                            }
                          </p>
                        )
                    }
                  </div>
                </div>

                <div className="second-row flex w-full items-center justify-between gap-6 md:flex-col md:justify-start">
                  {/** Price of codes field */}

                  {activation ? (
                    ""
                  ) : (
                    <div className=" flex w-1/2 flex-col items-end justify-center gap-2 md:w-full mb-4">
                      <label
                        className="w-full truncate"
                        htmlFor="number_lectures"
                      >
                        عدد مرات الاستخدام
                      </label>
                      <input
                        className="input"
                        type="number"
                        min={1}
                        id="number_lectures"
                        placeholder="000"
                        name="number_lectures"
                        autoComplete="on"
                        {...register("number_lectures", {
                          required: true,
                          valueAsNumber: true,
                        })}
                      />

                      {errors.number_lectures && (
                        <p className="text-[12px] text-red-900 ">
                          {errors.number_lectures.type === "required" &&
                            "برجاء ملئ هذا الحقل"}{" "}
                        </p>
                      )}
                      {
                        //!-------server errors -----

                        generateCodesErrors &&
                          generateCodesErrors?.response?.data?.errors
                            ?.number_lectures && (
                            <p className="w-full text-end text-[12px] text-red-900  ">
                              {
                                generateCodesErrors?.response?.data?.errors
                                  ?.number_lectures[0]
                              }
                            </p>
                          )
                      }
                    </div>
                  )}

                  {/** Type of codes field */}

                  <div
                    className={`flex flex-col items-end justify-center gap-2 mb-4  ${
                      activation ? "w-full" : "w-1/2"
                    }  md:w-full`}
                  >
                    <label className="w-full truncate" htmlFor="type">
                      نوع الأكواد{" "}
                    </label>
                    <select
                      className="input"
                      name="type"
                      id="type"
                      {...register("type", { required: true })}
                    >
                      <option value="char">أحرف فقط</option>
                      <option value="numbers">أرقام فقط</option>
                      <option value="mixed">أحرف و أرقام</option>
                    </select>

                    {errors.type && (
                      <p className="text-[12px] text-red-900 ">
                        {errors.type.type === "required" &&
                          "برجاء ملئ هذا الحقل"}{" "}
                      </p>
                    )}
                    {
                      //!-------server errors -----

                      generateCodesErrors &&
                        generateCodesErrors?.response?.data?.errors?.type && (
                          <p className="w-full text-end text-[12px] text-red-900  ">
                            {
                              generateCodesErrors?.response?.data?.errors
                                ?.type[0]
                            }
                          </p>
                        )
                    }
                  </div>
                </div>
                <div
                  className={`flex flex-col items-end justify-center gap-2 mb-4 w-full md:w-full`}
                >
                  <label className="w-full truncate" htmlFor="stage">
                    النوع
                  </label>
                  <select
                    className="input"
                    name="isexam"
                    id="isexam"
                    {...register("is_exam", { required: true })}
                  >
                    <option value="0">محاضرات</option>
                    <option value="1">امتحان</option>
                  </select>

                  {errors.is_exam && (
                    <p className="text-[12px] text-red-900 ">
                      {errors.type.is_exam === "required" &&
                        "برجاء ملئ هذا الحقل"}{" "}
                    </p>
                  )}
                  {
                    //!-------server errors -----

                    generateCodesErrors &&
                      generateCodesErrors?.response?.data?.errors?.is_exam && (
                        <p className="w-full text-end text-[12px] text-red-900  ">
                          {
                            generateCodesErrors?.response?.data?.errors
                              ?.is_exam[0]
                          }
                        </p>
                      )
                  }
                </div>
                <div
                  className={`flex flex-col items-end justify-center gap-2 mb-4 w-full md:w-full`}
                >
                  <label className="w-full truncate" htmlFor="stage">
                    الصف الدراسي
                  </label>
                  <select
                    className="input"
                    name="stage"
                    id="stage"
                    {...register("stage", { required: true })}
                  >
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>

                  {errors.stage && (
                    <p className="text-[12px] text-red-900 ">
                      {errors.type.stage === "required" &&
                        "برجاء ملئ هذا الحقل"}{" "}
                    </p>
                  )}
                  {
                    //!-------server errors -----

                    generateCodesErrors &&
                      generateCodesErrors?.response?.data?.errors?.stage && (
                        <p className="w-full text-end text-[12px] text-red-900  ">
                          {
                            generateCodesErrors?.response?.data?.errors
                              ?.stage[0]
                          }
                        </p>
                      )
                  }
                </div>

                {/** submit */}
                <button
                  disabled={!isValid || (submitLoading && submitGenerate.flag)}
                  className=" login-button  mt-6 w-full "
                  type="submit"
                >
                  {submitLoading && submitGenerate.flag ? (
                    <FontAwesomeIcon icon={faSpinner} spin />
                  ) : (
                    "استخراج"
                  )}
                </button>
              </form>
            </div>
          </div>
        </div>
      )}
      {/**
       * //!---------Table-----
       */}
      <div className="h-fit  w-full">{table}</div>
    </section>
  );
}

export default Codes;
